<template>
  <v-card class="card" elevation="0">

    <v-select
        ref="inputRef"
        dense
        filled
        no-data-text="الاجهزة غير متصلة او الرصيد غير كافي "
        outlined
        label="الجهاز"
        solo
        v-model="select"
        @input="setSelected"
        hide-selected
        :rules="[() => !!select || 'يجب اختيار الجهاز']"

        :items="devices"
        clearable
        item-value="token"
        :item-text="item => item.number+' ::: '+item.name"
    ></v-select>
    <Loading v-if="isloading"/>
    <v-data-table
        v-else
      :headers="headers"
      :items="desserts"
      :search="search"

      @click:row="handleClick">
      class="elevation-1 table">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field
            ></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2"
                     v-if="$store.getters.hasCreatePermission('group')"
                     outlined
                     color="indigo" v-bind="attrs" v-on="on"
                    @click="qrDialog = false"
              >
                اضافة {{buttonName}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
               هل تريد اضافة ترميز جديد؟
              </v-card-title>
              <v-card-text>يجب عليك ادخال اتزميز ارقام فقط. يتم استخدام الترميز لارسال الرسائل الى المجموعات . عند الارسال يجب اضافة 999 قلبل الترميز</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  الغاء
                </v-btn>

                <v-btn

                    color="green darken-1"
                    @click="openDialogNew()"
                    text>
                  التالي

                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <CreateDevice  v-if="isDialog" :is-dialog.sync="isDialog" :devices="groups" :device-token="selectValue"/>
          <GroupView  v-if="qrDialog" :qr-dialog.sync="qrDialog" :itemSelected="itemSelected" :item-token="selectValue"/>
        </v-toolbar>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
            :color="getColor(item.status)"
            dark
        >
          {{ item.status?"فعال":("متوقف") }}
        </v-chip>
      </template>

    </v-data-table>

  </v-card>
</template>
<script>
import GroupView from "@/components/dialogs/Groupview";
import CreateDevice from "@/components/createGroup";
import PushServicesSocket from "@/services/PushServicesSocket";
import Loading from "@/components/Loading";
export default {
  components:{
    GroupView,
    CreateDevice,
    Loading,
  },
  props: ['buttonName',"devices"],

  data: () => ({
    contentItems:[],
    groups:[],
    desserts:[

    ],
    isloading: false,
    search: "",
    qrDialog: false,
    selectValue:"",
    select: "",
    isDialog:false,
    itemSelected:null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    headers:[

      {
        text: 'الترميز',
        align: 'start',
        sortable: false,
        value: 'code',
      },
      { text: 'رقم الهاتف', value: 'phone' },
      { text: 'اسم المجموعة', value: 'name' },
      { text: 'تاريخ الانشاء', value: 'date' },
      { text: 'الحالة', value: 'status' },

    ],

  }),

  methods: {
   async setSelected(value){
      await this.getAll(value);
      this.selectValue= value;
    },
    openDialogNew(){
      this.dialog =false;
      this.isDialog= true;
    },

    async handleClick(value){

      this.itemSelected = value;
      this.qrDialog = true;

    },
    getColor(value){

      if (value==true||value=="connected") return 'green'
      else if (value=="disconnected") return 'orange'
      else return 'red'
    },
    async getAll(token){
     console.log(token);
      this.isloading = true;
      this.contentItems = await PushServicesSocket.GetDataDevice("/group/get",token);
      this.groups = await PushServicesSocket.GetDataDevice("/groups",token);

      this.desserts=[];
      if(this.contentItems.status)
        this.contentItems.data.forEach(item => {
          let expired_date ="";
          if(item.timestamp){
            expired_date = new Date();
            expired_date.setTime(parseInt(item.timestamp));
            expired_date =  expired_date.toISOString().substring(0,10);
          }
          this.desserts.push(
              {
                phone:item.number,
                name: item.name,
                code: item.group_key,
                device:item.serial,
                id:item.group_id,
                date:expired_date,
                status:item.status
              }
          );
        });


      this.isloading = false;
    },

  },
  mounted() {
    this.$root.$on('updateGroupView', () => {
      this.getAll(this.selectValue);
    });
  }
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);
}


</style>
