<template>
  <v-tabs
      color="deep-purple accent-4"
      centered
  >

    <v-tab  v-if="$store.getters.hasViewPermission('group')">المجموعات</v-tab>
    <v-tab>الرسائل</v-tab>


    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            المجموعات
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getAll()"
          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <DeviceTable button-name="ترميز المجموعات"  :devices="getDevices" />
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <v-container >
        <SendText :items-devices="getDevices"/>
      </v-container>
    </v-tab-item>


  </v-tabs>
</template>

<script>
import Loading from '@/components/Loading.vue';
import PushServicesSocket from "@/services/PushServicesSocket";

import ServicesManager from "@/services/ServicesManager";
import DeviceTable from '@/components/GroupTable';
import SendText from '@/components/SendMessageGroups';

export default {
  components: {
    Loading,

    DeviceTable,
    SendText
  },
  data: () =>({

    devicesItems:[],


    isloading: false,

  }),
  methods:{
    async getAll(){
      this.isloading = true;

      this.devicesItems   = await PushServicesSocket.GetData("/device/getall");
      if(this.devicesItems.status===true){
        this.devicesItems =  this.devicesItems.data;
      }
      this.desserts=[];
      this.isloading = false;
    },


  },
  computed:{
    getDevices (){
      //
      return this.devicesItems.filter(value =>ServicesManager.isAradyDevice(value));
    }

  },
  async mounted() {
    if(this.$store.getters.hasViewPermission("device"))
    await this.getAll();
  },

}
</script>


<style scoped>
.Sms {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>