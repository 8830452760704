<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري انشاء ترميز جديد"

    />
      <v-dialog
          width="60%"
          v-model="isDialog">

        <v-card elevation="0">
          <v-card-title>
            <span class="text-h5">انشاء ترميز جديد</span>
          </v-card-title>
          <v-card-text>
            <v-container >
              <v-row >
                <v-col>
                  <v-card ref="form" elevation="0">
                    <v-card-text>
                      <v-form v-model="isFormValid">

                        <v-autocomplete
                            ref="inputRef"
                            dense
                            filled
                            no-data-text="الاجهزة غير متصلة او لايوجد مجموعات "
                            outlined
                            label="اختار المجموعة"
                            solo
                            v-model="select"
                            @input="setSelected"
                            hide-selected
                            :rules="[() => !!select || 'يجب اختيار المجموعة']"

                            :items="devices.data"
                            clearable
                            item-value="id"
                            :item-text="item => item.name"
                        ></v-autocomplete>
                        <v-text-field

                            ref="inputRef"
                            outlined
                            dense

                            :rules="[() => !!destination || 'يجب ادخال الترميز']"
                            filled
                            color="rgb(200, 200, 200)"
                            type="number"
                            label="الترميز"
                            v-model="destination"
                            value=""
                            class="input-group--focused"
                        ></v-text-field>

                      </v-form>

                    </v-card-text>
                  </v-card>
                  <v-row>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="paink"

                           @click="close"
                    >
                      الغاء
                    </v-btn>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="green"
                           :disabled="!isFormValid"
                           @click="createGroup()"
                    >
                      انشاء
                    </v-btn>

                  </v-row>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';
import store from "@/store";

export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:{
    devices:[],
    deviceToken:String,
    isDialog: {
      default: false
    },
  },

  data () {
    return {
      select: "",
      isFormValid: false,
      isloading: false,
      selectToken:"",
      destination:"",
      isShow: false,
      successDialog: false,
      msgSucess:"تم انشاء الترميز بنجاح",
      msgError:"فشل ",

    }
  },

  methods: {
    setSelected(value){

      this.selectToken= value;
      let find = this.devices.data.find(item=> item.id==value);
      if(find)
      this.name =find.name;
    },
    close() {
      this.$emit('update:isDialog', false);
    },
    async createGroup() {

      try {

        this.isloading =true;

        const body = {
          'group_key': this.destination,
          'group_id': this.selectToken,

        };

        const response = await PushServicesSocket.createGroup(body,this.deviceToken);

        if(response.status)
        {
          this.msgSucess= this.msgSucess+"  "+ this.name +" :: "+ this.destination;
          this.successDialog = true;
          this.isloading = false;
          this.$refs.inputRef.reset();

          setTimeout(() => {
                this.close();
            this.$root.$emit('updateGroupView');
              },3000);


        }else{
          this.msgError = response.message;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    }

  },
  async mounted() {

  }
}
</script>

<style scoped>


</style>