<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري ارسال الرسالة"

    />

      <v-card elevation="0">

        <v-card-title>
          <span class="text-h5">ارسال رسالة</span>
        </v-card-title>
        <v-card-text>

          <v-container >
            <v-row >
              <v-col>
                <v-card ref="form" elevation="0">
                  <v-card-text>
                    <v-form v-model="isFormValid">

                      <v-select
                          ref="inputRef"
                          dense
                          filled
                          no-data-text="الاجهزة غير متصلة او الرصيد غير كافي"
                          outlined
                          label="الجهاز"
                          solo
                          v-model="select"
                          @input="setSelected"
                          hide-selected
                          :rules="[() => !!select || 'يجب اختيار الجهاز']"
                          :items="itemsDevices"
                          clearable
                          item-value="token"
                          :item-text="item => item.number+' ::: '+item.name"
                      ></v-select>

                      <v-select

                          ref="inputRef"

                          no-data-text="الجهاز غير متصل او لايوجد مجموعات "
                          outlined
                          label="اختار المجموعات"
                          solo
                          v-model="groupsSelected"
                          :rules="[() => !!groupsSelected || 'يجب اختيار المجموعة']"
                          :items="groupsItems.data"
                          clearable
                          chips
                          :item-text="item => item.name"
                          item-value="id"
                          multiple
                      >

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="chooseAllGroup"
                          >
                            <v-list-item-action>
                              <v-icon :color="isChooseSome > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                اختيار الكل
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>


                      </v-select>
                      <v-file-input
                          outlined
                          dense
                          chips
                          label="قم بختيار الملف"
                          ref="inputRef"
                          @change="Preview_image"
                          v-model="image"
                          clearable
                      >
                      </v-file-input>
                      <v-img :src="url" width="200" ></v-img>
                      <v-textarea
                          ref="inputRef"
                          outlined
                          dense
                          filled
                          color="rgb(200, 200, 200)"
                          label="الرسالة"
                          v-model="plantext"
                          value=""
                          :rules="[() => !!plantext || 'يجب ادخال الرسالة']"
                          required
                          class="input-group--focused"
                      ></v-textarea>
                    </v-form>

                  </v-card-text>
                </v-card>
                <v-alert
                    class="mt-5"
                    left
                    border="left"
                    color="red"
                    dismissible
                    elevation="9"
                    type="error"
                    v-model="isShow"
                >{{msgError}}</v-alert>
                <v-alert
                    class="mt-5"
                    left
                    border="left"
                    color="green"
                    dismissible
                    elevation="9"
                    type="success"
                    v-model="successDialog"
                >"تم ارسال الرسالة بنجاح!"</v-alert>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2"
                         width="20%"
                         outlined
                         color="green"
                         :disabled="!isFormValid"
                         @click="sendMessage()"
                  >
                    ارسال
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>


      </v-card>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:[
    "itemsDevices"
  ],
  data () {
    return {
      isFormValid: false,
      select: "",
      selectToken: "",
      groupsSelected:[],
      groupsItems:[],
      isloading: false,
      dialog: false,
      progress:0,
      plantext:"",
      device:"",
      isShow: false,
      successDialog: false,
      msgSucess:"تم ارسال الرسالة بنجاح بنحاح",
      msgError:"فشل في الارسال",

      url: null,
      image: null,

    }
  },

  methods: {
    Preview_image() {
      this.url= URL.createObjectURL(this.image)
    },
    chooseAllGroup () {
      if (this.isChooseAll) {
        this.groupsSelected = [];
      } else {
        this.groupsSelected = this.groupsItems.data.map(e=> e.id);

      }
    },

   async setSelected(value){

      this.selectToken= value;
      await this.getGroups(value);
    },
    async getGroups(token){
      this.groupsItems = await PushServicesSocket.GetDataDevice("/groups",token);
    },
    async sendMedia(destination) {
      try {

        this.isloading =true;

        const messagebody = {
          'message': this.plantext,
          'number': destination,
          "type":"media",
          "file":this.image,
        };

        this.progress = 0;
        const response = await PushServicesSocket.sendMediaWhatsapp(messagebody,(event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }, this.selectToken);
        if(response.status)
        {
          this.successDialog = true;
          this.isloading = false;
          this.isShow = false;
          this.$refs.inputRef.reset()
        }else{
          this.msgError = response.message??this.msgError;
          this.isShow = true;
          this.isloading =false;
        }
      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    },
    async sendMessageText(destination) {

      try {

        this.isloading =true;
        const messagebody = {
          'message': this.plantext,
          'number': destination,
        };


        const response = await PushServicesSocket.sendTextWhatsapp(messagebody, this.selectToken);

        if(response.status)
        {
          this.successDialog = true;
          this.isloading = false;
          this.isShow = false;
          this.$refs.inputRef.reset()
        }else{

          this.msgError = response.message??this.msgError;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {

        this.isShow = true;
        this.isloading =false;
      }
    },
    async sendMessage() {

      if(this.image){
        this.groupsSelected.forEach(async value => {

          await this.sendMedia(`999${value}`);
        });
      }

      else
      {

        this.groupsSelected.forEach(async value => {

          await this.sendMessageText(`999${value}`);
        });
      }

      this.groupsSelected=[];
    }

  },
  computed:{
  isChooseAll () {
    return this.groupsSelected?.length??0 === this.groupsItems.data.length
  },
    isChooseSome () {
    return this.groupsSelected?.length??0 > 0
  },
    icon () {
      if (this.isChooseAll) return 'mdi-close-box'
      if (this.isChooseSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },


}
}
</script>

<style scoped>


</style>